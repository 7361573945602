import {
    CLIENT_TYPE_INDIVIDUAL,
    CLIENT_TYPE_INSTITUTION,
  } from "../../../../constants/client";
  import { IFormField } from "../../../../utils/form_factory";
  
  export const GeneralFormFields =
    (formType: string) =>
    (clientType: string, isMember: boolean) =>
    (
      ClientTypes: any[],
      Gender?: any[],
    ): IFormField[] =>
      [
        // {
        //   name: "is_staff",
        //   initailValue: false,
        //   label: "Is Client a Staff Member?",
        //   type: "text",
        //   uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        //   uiType: "checkbox",
        //   initailValueReadPath: "is_member",
        //   isHidden: formType === "Update",
        // },
        {
          name: "type",
          initailValue: "",
          label: "Client Type",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "select",
          options: ClientTypes,
          selector: {
            value: (option: any) => option.value,
            label: (option: any) => option.name,
          },
          isDisabled: true,
          isHidden: true,
          initailValueReadPath: "type",
        },    
        {
          name: "first_name",
          initailValue: "",
          label: "First Name",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "text",
          isHidden:
            clientType === CLIENT_TYPE_INSTITUTION
        },
        {
          name: "last_name",
          initailValue: "",
          label: "Last Name",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "text",
          isHidden:
            clientType === CLIENT_TYPE_INSTITUTION
        },
        {
          name: "date_of_birth",
          initailValue: "",
          label: "Date of Birth",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "date",
        },
        {
          name: "gender",
          initailValue: "",
          label: "Gender",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "select",
          options: Gender,
          selector: {
            value: (option: any) => option.value,
            label: (option: any) => option.name,
          },
          isHidden:
            clientType === CLIENT_TYPE_INSTITUTION
        },
        {
          name: "password",
          initailValue: "",
          label: "Create Password",
          type: "password",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "password",
        },
        {
          name: "confirm_password",
          initailValue: "",
          label: "Confirm Password",
          type: "password",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "password",
        },
    ];
  
  export const ContactFormFields = (Countries?: any): IFormField[] => [
    {
      name: "email",
      initailValue: "",
      label: "Active Email Address",
      type: "text",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "text",
    },
    {
      name: "phone_number",
      initailValue: "256",
      label: "Phone Number",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "phone",
      customFormat: 'XXX-XXX-XXXXXX',
    },
    {
        name: "address",
        initailValue: "",
        label: "Address",
        type: "text",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "text",
    },
    {
      name: "country",
      initailValue: "Uganda",
      label: "Country",
      type: "text",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "select",
      options: Countries,
      selector: {
        value: (option: any) => option.country,
        label: (option: any) => option.country,
      },
    },
    {
      name: "city",
      initailValue: "",
      label: "City",
      type: "text",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "text",
    },
    {
      name: "postal_code",
      initailValue: "",
      label: "Postal Code",
      type: "text",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "text",
    },
  ];
  
  export const AttachmentFormFields = (): IFormField[] => [
    {
        name: "id_number",
        initailValue: "",
        label: "ID Number (NIN)",
        type: "text",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "text",
    },
    {
        name: "profile_picture",
        initailValue: "",
        label: "Profile Picture",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "file",
    },
    {
      name: "id_picture_front",
      initailValue: "",
      label: "ID Front",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "file",
    },
    {
      name: "id_picture_back",
      initailValue: "",
      label: "ID Back",
      uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 6 },
      uiType: "file",
    },
    
  ];
  