import jwtDecode from "jwt-decode";
import { createContext, ReactNode, useEffect, useReducer, useState } from "react";
import LoadingScreen from "../components/LoadingScreen";
import axiosInstance from "../api";
import axios from "axios";
import { API_BASE_URL } from "../api/constants";

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type AuthUser = null | Record<string, any>;

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

enum Types {
  Init = "INIT",
  Login = "LOGIN",
  Logout = "LOGOUT",
}

type JWTAuthPayload = {
  [Types.Init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Login]: { user: AuthUser };
};

type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext: any = createContext({
  ...initialState,
  method: "JWT",
  login: (email: string, password: string) => Promise.resolve(),
  logout: () => { },
});

// props type
type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getUserDetails, setGetUserDetails] = useState(false);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${API_BASE_URL}auth/token/`, {  
        email,
        password,
      });

      const { access, refresh }: any = response.data;
      setSession(access);

      // Fetch user details immediately after setting the accessToken
      const user = await getUser(access);

      dispatch({
        type: Types.Login,
        payload: { user: user },
      });
      setGetUserDetails(true);
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  };

  const logout = () => {
    setSession(null);
    setGetUserDetails(false);
    dispatch({ type: Types.Logout });
  };

  // const getUser = async (): Promise<any> => {
  //   try {
  //     const response = await axiosInstance.get(`/user/me/`);
  //     return response.data;
  //   } catch (error: any) {
  //     return {}
  //   }
  // };
  const getUser = async (accessToken: string): Promise<any> => {
    try {
      // Extract user ID from the access token
      const decodedToken: any = jwtDecode<{ user_id: string }>(accessToken);
      const userId = decodedToken.user_id;
  
      // Make a request to the /users/{id}/ endpoint
      const response = await axiosInstance.get(`/users/${userId}/`);
      
      // Return user details from the response
      return response.data;
    } catch (error: any) {
      return {};
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem("accessToken");

  //       if (accessToken && isValidToken(accessToken)) {
  //         dispatch({
  //           type: Types.Init,
  //           payload: {
  //             user: await getUser(),
  //             isAuthenticated: true,
  //           },
  //         });
  //       } else {
  //         dispatch({
  //           type: Types.Init,
  //           payload: {
  //             user: null,
  //             isAuthenticated: false,
  //           },
  //         });
  //       }
  //     } catch (err) {
  //       dispatch({
  //         type: Types.Init,
  //         payload: {
  //           user: null,
  //           isAuthenticated: false,
  //         },
  //       });
  //     }
  //   })();
  // }, [getUserDetails]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
  
        if (accessToken && isValidToken(accessToken)) {
          // Get user details using the access token
          const user = await getUser(accessToken);
  
          dispatch({
            type: Types.Init,
            payload: {
              user: user,
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false,
          },
        });
      }
    })();
  }, []);




  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
